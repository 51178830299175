<template>
  <div>
    <topbar drawer :title="navbarTitle"></topbar>
    <v-container v-if="!loading">
      <v-row dense>
        <v-col cols="12" sm="8" class="d-flex">
          <div class="text-h3 text-truncate">{{ship.name}}</div>
          <v-spacer></v-spacer>
          <div class="text-h5" style="align-self: flex-end">from</div>
        </v-col>
        <v-col cols="12" sm="4" class="d-flex">
          <v-hover v-slot="{ hover }">
            <div :class="{ 'custom-anchor': hover }" class="text-h5" style="cursor: pointer;align-self: flex-end;" @click="manufacturerDetail(ship.manufacturerId)">{{ship.manufacturerName}}</div>
          </v-hover>
        </v-col>
      </v-row>
      <v-row dense>
         <v-col cols="12" sm="8">
          <v-img v-if="ship && ship.images" min-height="440" max-height="460" :src="ship.images.big"></v-img>
          <div class="mt-4">{{ship.description}}</div>
          <v-divider class="my-4"/>
          <v-data-table
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :headers="userHeaders"
            :items="userShips"
            disable-pagination
            hide-default-footer
            dense
          >
          <template v-slot:[`item.userName`]="{ item }">
            <v-hover v-if="!!item.userId" v-slot="{ hover }">
              <div :class="{ 'custom-anchor': hover }" class="text-truncate" style="cursor: pointer;" @click="userDetail(item.userId)">{{ item.userName }}</div>
            </v-hover>
            <div v-show="!item.userId" :class="{ 'custom-anchor': hover }" class="text-truncate">{{ item.userName }}</div>
          </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" sm="4">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in shipMeta"
                  :key="item.name"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import Topbar from '../components/Topbar';

  export default {
    name: 'ShipDetail',

    components: {
      Topbar
    },

    props: {
      shipId: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        navbarTitle: 'Ship detail',
        shipMeta: [],
        headers: [
          {
            text: 'Type',
            align: 'start',
            filterable: false,
            value: 'name',
          },
          { text: 'Value', value: 'value' },
        ],
        sortBy: 'quantity',
        sortDesc: true,
        userHeaders: [
          {
            text: 'Ship owners',
            align: 'start',
            filterable: false,
            value: 'userName',
          },
          { text: 'Anzahl', value: 'quantity' }
        ]
      };
    },

    computed: {
      loading() {
        return this.$store.getters.loading;
      },
      user() {
        return this.$store.getters['user/getUser'];
      },
      ship() {
        return this.$store.getters['ships/getShip'];
      },
      userShips() {
        return this.$store.getters['ships/getUserShips'];
      }
    },

    watch: {
      ship(ship) {
        const shipMetaOrder = {
          type: 0,
          length: 1,
          beam: 2,
          height: 3,
          size: 4,
          mass: 5,
          cargocapacity: 6,
          scm_speed: 7,
          afterburner_speed: 8,
          min_crew: 9,
          max_crew: 10,
          pitch_max: 11,
          yaw_max: 12,
          roll_max: 13,
          xaxis_acceleration: 14,
          yaxis_acceleration: 15,
          zaxis_acceleration: 16,
          price: 17,
          production_status: 18
        }
        for (const key in ship) {
          if (Object.hasOwnProperty.call(ship, key)) {
            if (!['_id', 'shipId', 'description', 'manufacturerId', 'manufacturerName', 'images', 'name', 'url', '__v'].includes(key)) {
              switch (key) {
                case 'length':
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key] + ' m', order: shipMetaOrder[key]});
                  break;
                case 'beam':
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key] + ' m', order: shipMetaOrder[key]});
                  break;
                case 'height':
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key] + ' m', order: shipMetaOrder[key]});
                  break;
                case 'cargocapacity':
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key] + ' SCU', order: shipMetaOrder[key]});
                  break;
                case 'mass':
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key] + ' kg', order: shipMetaOrder[key]});
                  break;
                case 'price':
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key] + ' Dollar', order: shipMetaOrder[key]});
                  break;
                case 'lti':
                this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: this.$t(`global.${ship[key]}`), order: shipMetaOrder[key]});
                  break;
                case 'warbound':
                this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: this.$t(`global.${ship[key]}`), order: shipMetaOrder[key]});
                  break;
                case 'flagship':
                this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: this.$t(`global.${ship[key]}`), order: shipMetaOrder[key]});
                  break;
                default:
                  this.shipMeta.push({name: this.$t(`shipDetail.${key}`), value: ship[key], order: shipMetaOrder[key]});
                  break;
              }
            }
          }
        }
        this.shipMeta.sort((a, b) => a.order - b.order);
      }
    },

    beforeMount() {
      this.loadItems();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },

    methods: {
      async loadItems() {
        this.$store.dispatch('loading', true, {root: true});
        if (!this.user.userId) {
          await this.$store.dispatch('user/getUser', this.$auth && this.$auth.user.sub);
        }
        await this.$store.dispatch('ships/getShip', this.shipId);
        this.$store.dispatch('loading', false, {root: true});
      },
      manufacturerDetail(manufacturerId) {
        this.$router.push({name: 'ManufacturerDetail', query: {manufacturerId}});
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      },
      userDetail(userId) {
        this.$router.push({name: 'user', query: {userId}});
      }
    }
  };
</script>

<style scoped>
.custom-anchor {
  color: skyblue;
  text-decoration: underline;
}
</style>
